import React, { useEffect } from "react";
import { ReactComponent as LogoProfessionista } from "./../../../assets/svg/SvgImgProfessionista.svg";
import { ReactComponent as LogoPrivato } from "./../../../assets/svg/SvgImgPrivato.svg";
import { ReactComponent as LogoRivenditore } from "./../../../assets/svg/SvgImgRivenditore.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { register } from "../loginAPI";
import { ArrowBack } from "@mui/icons-material";
import { getAPIErrorMessage } from "../../../common/utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Field from "../../profilo/components/Field";
import { AppBar, Box, Button, Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, Tab, Toolbar, Typography, useTheme } from "@mui/material";
import PrivacyPolicy from "../../../assets/documents/privacy_policy.pdf";
import { Footer } from "../../homepage/Footer";

export function Register() {
  const c = useTheme().palette;
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const [informativaClicked, setInformativaClicked] = React.useState(false);
  const [informativaError, setInformativaError] = React.useState(false);
  const [termsClicked, setTermsClicked] = React.useState(false);
  const [termsError, setTermsError] = React.useState(false);

  const tabs = [
    {
      label: "Sono un Professionista",
      value: "0",
      tipologia: "PA",
      icon: <LogoProfessionista style={{ maxWidth: "50px", maxHeight: "50px" }} />,
      title: "Vuoi acquistare i nostri servizi?",
      subtitle: "Registrandoti potrai acquistare i programmi benessere della L.S. Genius per le tue consulenze professionali e accedere a una vasta libreria sonora per utilizzare la sonorostimolazione (Audio Brain Stimulation®).",
      nb: "NB: I campi contrassegnati con un asterisco (*) sono obbligatori.",
    },
    {
      label: "Sono un Privato",
      value: "1",
      tipologia: "P",
      icon: <LogoPrivato style={{ maxHeight: "50px", maxWidth: "50px" }} />,
      title: "Vuoi usufruire dei nostri Servizi?",
      subtitle: "Registrati! Potrai scegliere il programma benessere che fa per te e accedere a una vasta libreria sonora per ascoltare comodamente da casa tua audio creati su misura per il tuo benessere (Audio Brain Stimulation®).",
    },
    { label: "Sono un Rivenditore", value: "2", tipologia: "PP", icon: <LogoRivenditore style={{ maxWidth: "50px", maxHeight: "50px" }} />, 
    title: "Vuoi rivendere i nostri servizi?", 
    subtitle: "Registrandoti potrai acquistare e rivendere i nostri servizi ai tuoi Clienti." },
  ];
  const anagFields = [
    { scope: "nome", label: "Nome", reqPa: true },
    { scope: "cognome", label: "Cognome", reqPa: true },
    { scope: "email", label: "Email", reqPa: true },
    { scope: "telefono", label: "Telefono", reqPa: true }
  ];
  const privFields = [
    { scope: "indirizzo", label: "Indirizzo" },
    { scope: "cap", label: "CAP" },
    { scope: "citta", label: "Città" },
    { scope: "cf", label: "Codice Fiscale" },
    { scope: "sesso", label: "Sesso" },
    { scope: "peso", label: "Peso (kg)", p: "Inserisci Peso in kg" },
    { scope: "altezza", label: "Altezza (cm)", p: "Inserisci Altezza in cm" },
    { scope: "data_nascita", label: "Data di Nascita" },
  ];
  const attFields = [
    { scope: "piva", label: "Partita IVA", p: "Inserisci P. IVA" },
    { scope: "cf", label: "Codice Fiscale", reqPa: true },
    { scope: "ragione_sociale", label: "Ragione Sociale" },
    { scope: "indirizzo", label: "Indirizzo", reqPa: true },
    { scope: "cap", label: "CAP", reqPa: true },
    { scope: "citta", label: "Città", reqPa: true },
    { scope: "pec", label: "PEC" },
    { scope: "sdi", label: "SDI" },
    { scope: "codice_rivenditore", label: "Codice Rivenditore", reqPa: true },
  ];

  const [tabSelected, setTabSelected] = React.useState("0");
  const [formData, setFormData] = React.useState({});
  const [isLoading, setLoading] = React.useState(false);

  const [registerComplete, setRegisterComplete] = React.useState(false);
  const [erroreRegistrazione, setErroreRegistrazione] = React.useState(false);

  // prendere state da navigazione
  useEffect(() => {
    if (state?.type) {
      state.type === "professionista" && setTabSelected("0");
      state.type === "privato" && setTabSelected("1");
      state.type === "rivenditore" && setTabSelected("2");
    }
  }, [state]);

  const handleRegister = async () => {

    if (!informativaClicked) {
      setInformativaError(true);
    }

    if (!termsClicked) {
      setTermsError(true);
    }

    if (!informativaClicked || !termsClicked) {
      return;
    }

    setLoading(true);
    const slctedTipologia = tabs.find((t) => t.value.toString() === tabSelected).tipologia;
    const { nome, cognome, email, telefono, cf, indirizzo, cap, citta } = formData;
    let data = { tipologia: slctedTipologia, nome, cognome, telefono, email, cf, indirizzo, cap, citta };
    if (slctedTipologia === "P") {
      const { peso, altezza, data_nascita, sesso } = formData;
      data = { ...data, peso, altezza, data_nascita, sesso };
    } else {
      const { piva, ragione_sociale, codice_rivenditore, pec, sdi } = formData;
      data = { ...data, piva, ragione_sociale, codice_rivenditore, pec, sdi };
    }
    try {
      await register(data);
    } catch (e) {
      setErroreRegistrazione(getAPIErrorMessage(e));
      return;
    } finally {
      setLoading(false);
    }
    setRegisterComplete(true);
  };

  return (
    <div style={{ display: "flex", height: "100vh", flexDirection: "column", width: '100%' }}>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <AppBar position="static" sx={{ backgroundColor: c.secondary.main }}>
          <Toolbar>
            <IconButton sx={{ mr: 2 }} onClick={() => navigate("/tommy/login", { replace: true })}>
              <ArrowBack sx={{ color: "#fff" }} />
            </IconButton>
            <Box sx={{ py: 2 }}>
              <Typography sx={{ color: c.secondary.light, fontFamily: "Poppins-SemiBold", fontSize: '1.75rem' }}>Registrazione</Typography>
              <Typography sx={{ color: c.secondary.light, fontSize: '1rem' }}>Prima di accedere, completa il form per effettuare la registrazione.</Typography>
            </Box>
          </Toolbar>
        </AppBar>

        <TabContext value={tabSelected || ""}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1, mt: 2 }}>
            <TabList onChange={(e, newTab) => setTabSelected(newTab)} variant="scrollable" scrollButtons allowScrollButtonsMobile>
              {tabs.map((tab) => (
                <Tab value={tab.value} icon={tab.icon} iconPosition="start" label={tab.label} sx={{ px: 4, fontFamily: "Poppins-SemiBold", "&.Mui-selected": { color: c.secondary.main } }} key={tab.value} />
              ))}
            </TabList>
          </Box>
          {tabs.map((tab) => (
            <TabPanel value={tab.value.toString()} index={tab.value} key={tab.value} sx={{ mx: '3%' }}>
              <Typography variant="title" color="primary.dark">
                {tab.title}
              </Typography>
              <Typography sx={{ color: c.primary.background, mt: 1 }}>{tab.subtitle}</Typography>
              <Typography sx={{ color: c.primary.background, mt: 1 }}><b>{tab.nb}</b></Typography>
              <Box sx={{ mt: 4 }}>
                <Box bgcolor="#FDFDFD" borderRadius={5} sx={{ py: 2 }}>
                  <Typography sx={{ color: c.primary.main }}>Dati Anagrafici:</Typography>
                  <Grid container spacing={2} columnSpacing={8}>
                    {(tab.tipologia === "P" ? [...anagFields, ...privFields] : anagFields).map((f) => (
                      <Field userType={tab.tipologia} key={f.scope} field={f} data={formData} setNewVal={(val) => setFormData((prev) => ({ ...prev, [f.scope]: val }))} />
                    ))}
                  </Grid>
                </Box>
                {tab.tipologia !== "P" && (
                  <Box bgcolor="#FDFDFD" borderRadius={5} sx={{ mt: 4, py: 2 }}>
                    <Typography sx={{ color: c.primary.main }}>Dati Attività:</Typography>
                    <Grid container spacing={4}>
                      {attFields.map((f) => (
                        <Field userType={tab.tipologia} key={f.scope} field={f} data={formData} setNewVal={(val) => setFormData((prev) => ({ ...prev, [f.scope]: val }))} />
                      ))}
                    </Grid>
                  </Box>
                )}
              </Box>

              <FormControl
                required
                error={informativaError}
                component="fieldset"
                sx={{  mt:3 }}
                variant="standard"
              >
                <FormLabel component="legend">Informativa Privacy</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={informativaClicked} onChange={() => {
                        setInformativaClicked(!informativaClicked);
                        setInformativaError(false);
                      }} name="gilad" />
                    }
                    label={<span>Letta <a href={process.env.REACT_APP_TOMMY_API_URL + (tab.tipologia == "P" ? "/documents/InformativaPrivacy_PersoneFisiche.pdf" : "/documents/InformativaPrivacy_Professionisiti_Rivenditori.pdf")} target="_blank">l’informativa privacy</a>, presto il consenso al trattamento dei miei dati
                      personali per la registrazione alla piattaforma.</span>}
                  />

                </FormGroup>
                {
                  informativaError && <FormHelperText>Richiesto</FormHelperText>
                }
              </FormControl>
                <br/>
              <FormControl
                required
                error={termsError}
                component="fieldset"
                sx={{ mt: 1 }}
                variant="standard"
              >
                <FormLabel component="legend">Termini e Condizioni</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={termsClicked} onChange={() => {
                        setTermsClicked(!termsClicked);
                        setTermsError(false);
                      }} name="gilad" />
                    }
                    label={<span>Accetto i seguenti <a href={process.env.REACT_APP_TOMMY_API_URL + "/documents/Termini_e_Condizioni.pdf"} target="_blank">termini e condizioni</a>.</span>}
                  />

                </FormGroup>
                {
                  termsError && <FormHelperText>Richiesto</FormHelperText>
                }
              </FormControl>
            </TabPanel>
          ))}
          <Box sx={{ mx: 5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>



            <Button variant="contained" size="large" onClick={handleRegister} disabled={isLoading} sx={{ mb: 2 }}>
              <Typography variant="s">{isLoading ? "Caricamento ..." : "REGISTRATI"}</Typography>
            </Button>
          </Box>
        </TabContext>

        <Dialog open={registerComplete} onClose={() => setRegisterComplete(false)}>
          <Box sx={{ p: 4, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Typography sx={{ fontFamily: "Poppins-SemiBold", fontSize: "24px", color: c.primary.main }}>Registrazione completata</Typography>
            <Typography sx={{ mt: 2, color: c.primary.background, fontSize: "16px", cursor: "pointer" }}>La tua registrazione è stata completata, ti è stata inviata un email con una password per effettuare il primo accesso.</Typography>
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setRegisterComplete(false);
                navigate("/tommy/login", { replace: true });
              }}
            >
              ACCEDI
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={!!erroreRegistrazione} onClose={() => setErroreRegistrazione(false)}>
          <Box sx={{ p: 4, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Typography sx={{ fontFamily: "Poppins-SemiBold", fontSize: "24px", color: c.text.error }}>Errore registrazione</Typography>
            <Typography sx={{ mt: 2, fontSize: "16px", cursor: "pointer" }}>
              <span style={{ color: c.text.error, whiteSpace: "pre-line" }}>{erroreRegistrazione || "Si è verificato un'errore durante la registrazione del tuo profilo. Controlla di aver inserito correttamente i dati e riprova."}</span>
            </Typography>
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                setErroreRegistrazione(false);
              }}
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Footer/>
      </Box>
    
    </div>
  );
}
